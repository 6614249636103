



import { Component, Mixins } from 'vue-property-decorator'
import Challenge from '@/components/organisms/study/Challenge.vue'
import Contents3 from '@/views/student/study/Contents3.vue'

@Component({
  components: {
    Challenge,
  },
})
export default class Challenge6 extends Mixins(Contents3) {}
